import { SHOW_MARKETING_BADGES } from 'constants/monetateExperiments';
import { useExperiments } from 'components/Experiment/useExperiments';
import { splitPath } from 'utils/get-page-type';
import history from 'utils/history';

const restrictions = [
  {
    isNotVisible: () => splitPath(history.getLocation().pathname)[2] === 'meal-deals',
    badge: 'Meal deal',
  },
];

export const useMarketingBadge = (marketingBadges: { name: string }[] | null) => {
  const { getDecisionById } = useExperiments();

  const showMarketingBadgesFlag =
    getDecisionById(SHOW_MARKETING_BADGES.experiment).variant ===
    SHOW_MARKETING_BADGES.variant.showBadges;

  if (!showMarketingBadgesFlag || !marketingBadges?.length) {
    return { showMarketingBadges: false };
  }

  const marketingBadgesFiltered = marketingBadges.filter(
    marketingBadge =>
      !restrictions.some(
        ({ isNotVisible, badge }) => badge === marketingBadge.name && isNotVisible(),
      ),
  );

  return {
    marketingBadgesFiltered,
    showMarketingBadges: !!marketingBadgesFiltered.length,
  };
};
